import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import L3 from '../../components/ExcerciseTypes/Design/Listen3'


const json = {

  1: { // Exercise num
    unit : 'Unit 4',
    id :  'WB2-U4-P30-E1',
    audio: 'img/FriendsPlus/Page30/E1/Audio/audio.e1.p30.mp3',
    video: '',
    component: D1,
    // isAllowSubmit: true,
    exerciseKey: 'img/FriendsPlus/Page30/E1/Key/Key.png',
    titleImage: 'img/FriendsPlus/Page30/E1/title.jpg',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page30/E1/1.jpg'  },
        { url: 'img/FriendsPlus/Page30/E1/2.jpg' , audioUrl:'img/FriendsPlus/Page30/E1/Audio/tieude.e1.p30.mp3' },
        { url: 'img/FriendsPlus/Page30/E1/3.jpg'  },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E1/4.jpg'  },
      ],
    ]
  },


  2: { // Exercise num
    unit : 'Unit 4',
    id :  'WB2-U4-P30-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page30/E2/Key/Key.png',
    inputSize: 200,
    titleImage: 'img/FriendsPlus/Page30/E2/1.jpg',
    // titleQuestion: [{ num: '3', title: 'Write the missing word. ', color: "#203c8e"}],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600;'>1. &emsp;</span><u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:25px;'> motorbike </u> <span style='font-weight:600;'>&emsp;&emsp;&emsp; 4. </span> # </p>" +
          "<p><span style='font-weight:600;'>2. </span> # <span style='font-weight:600;'>5. </span> # </p>" +
          "<p><span style='font-weight:600;'>3. </span> # <span style='font-weight:600;'>6. </span> # </p>",
        answer: [
          "car",
          "bus", "truck",
          "bike", "boat",
        ],
        type: 'longAnwser'
      },
    ]
  },

  3: { // Exercise num
    unit : 'Unit 4',
    id :  'WB2-U4-P30-E3',
    audio: '',
    video: '',
    component: L3,
    titleImage: 'img/FriendsPlus/Page30/E3/title.jpg',
    question: [
      {
        imgUrl: [
          'img/FriendsPlus/Page30/E3/title.jpg',
          'img/FriendsPlus/Page30/E3/1.jpg',
          'img/FriendsPlus/Page30/E3/2.jpg',
          'img/FriendsPlus/Page30/E3/3.jpg',
          'img/FriendsPlus/Page30/E3/4.jpg',
          'img/FriendsPlus/Page30/E3/5.jpg',
          'img/FriendsPlus/Page30/E3/6.jpg',

        ],
        audioUrl: [
          null,
          'img/FriendsPlus/Page30/E3/Audio/1.mp3',
          'img/FriendsPlus/Page30/E3/Audio/2.mp3',
          'img/FriendsPlus/Page30/E3/Audio/3.mp3',
          'img/FriendsPlus/Page30/E3/Audio/4.mp3',
          'img/FriendsPlus/Page30/E3/Audio/5.mp3',
          'img/FriendsPlus/Page30/E3/Audio/6.mp3',
          // 'img/FriendsPlus/Page30/E3/Audio/4.mp3',
        ],
        isLongAudio: []
      },
    ],
    questionImage: []
  },
}

export default json;