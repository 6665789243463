import UI from '../../components/ExcerciseTypes/UseIt';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB2-U1-P12-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page12/2.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB2-U1-P12-E2',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page12/3.jpg' },
      ],
    ]
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'WB2-U1-P12-E3',
    audio: 'img/FriendsPlus/Page12/Audio/Track 001.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page12/E3/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 3,
    // titleQuestion: [{ num: '2', title: "Listen and circle.", color: '#364E96' }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page12/E3/titleImgE3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page12/E3/03.jpg' },
        { url: 'img/FriendsPlus/Page12/E3/04.jpg' },
        { url: 'img/FriendsPlus/Page12/E3/05.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page12/E3/07.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page12/E3/08.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page12/E3/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page12/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page12/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page12/E3/12.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page12/E3/14.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page12/E3/15.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page12/E3/16.jpg' },
      ]
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;