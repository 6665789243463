
import L2 from '../../components/ExcerciseTypes/Design/Listen2'
import L3 from '../../components/ExcerciseTypes/Design/Listen3'

const json = {
  1: { // Exercise num
    unit : 'Unit 1',
    id :  'WB2-U1-P11-E1',
    audio: '',
    video: '',
    component: L3,
    titleImage: 'img/FriendsPlus/Page11/E2/titleImg.jpg',
    question: [
      {
        imgUrl: [
          'img/FriendsPlus/Page11/E1/titleImage.jpg',
          'img/FriendsPlus/Page11/E1/1.jpg',
          'img/FriendsPlus/Page11/E1/2.jpg',
          'img/FriendsPlus/Page11/E1/3.jpg',
          'img/FriendsPlus/Page11/E1/4.jpg',

        ],
        audioUrl: [
          null,
          'img/FriendsPlus/Page11/E1/Audio/1.mp3',
          'img/FriendsPlus/Page11/E1/Audio/2.mp3',
          'img/FriendsPlus/Page11/E1/Audio/3.mp3',
          'img/FriendsPlus/Page11/E1/Audio/4.mp3',
          // 'img/FriendsPlus/Page11/E2/Audio/4.mp3',
        ],
        isLongAudio: []
      },
    ],
    questionImage: []
  },
  2: { // Exercise num
    unit : 'Unit 1',
    id :  'WB2-U1-P11-E2',
    audio: '',
    video: '',
    component: L2,
    titleImage: 'img/FriendsPlus/Page11/E2/titleImg.jpg',
    question: [
      {
        imgUrl: [
          'img/FriendsPlus/Page11/E2/titleImg.jpg',
          'img/FriendsPlus/Page11/E2/1.jpg',
          'img/FriendsPlus/Page11/E2/2.jpg',
          'img/FriendsPlus/Page11/E2/3.jpg',
          'img/FriendsPlus/Page11/E2/4.jpg',

        ],
        audioUrl: [
          null,
          'img/FriendsPlus/Page11/E2/Audio/1.mp3',
          'img/FriendsPlus/Page11/E2/Audio/2.mp3',
          'img/FriendsPlus/Page11/E2/Audio/3.mp3',
          'img/FriendsPlus/Page11/E2/Audio/4.mp3',
          // 'img/FriendsPlus/Page11/E2/Audio/4.mp3',
        ],
        isLongAudio: []
      },
    ],
    questionImage: []
  },
}

export default json;