
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {


  1: { // Exercise num
    unit: 'Culture 5',
    id: 'WB2-C-P56-E1',
    audio: '',
    video: '',
    component: D1,
    // exerciseKey: '',
    // recorder: true,
    // typeInput: 'center',
    // isLargeInput: true,
    fontSize: 30,
    exerciseKey: 'img/FriendsPlus/Page56/E1/Key/answerKeyP56E1.jpg',
    inputHeight: '100%',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page56/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/3.jpg', input: true, answer: 'boots' },
        { url: 'img/FriendsPlus/Page56/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E1/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/7.jpg', input: true, answer: 'mountains' },
        { url: 'img/FriendsPlus/Page56/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/9.jpg', input: true, answer: 'backpack' },
        { url: 'img/FriendsPlus/Page56/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E1/11.jpg' },
      ],

    ]
  },
  2: { // Exercise num
    unit: 'Culture 5',
    id: 'WB2-C-P56-E2',
    audio: '',
    video: '',
    component: D1,
    // exerciseKey: '',
    // recorder: true,
    isAllowSubmit: true,
    hideBtnFooter:true,
    // isLargeInput: true,
    fontSize: 30,
    exerciseKey: 'img/FriendsPlus/Page56/E2/Key/answerKeyP56E2.jpg',
    inputHeight: '100%',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page56/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/3.jpg', input: true, answer: 'oll' },
        { url: 'img/FriendsPlus/Page56/E2/4.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page56/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/6.jpg', input: true, answer: 'oard' },
        { url: 'img/FriendsPlus/Page56/E2/7.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page56/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/9.jpg', input: true, answer: 'ame' },
        { url: 'img/FriendsPlus/Page56/E2/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/12.jpg', input: true, answer: 'oll' },
        { url: 'img/FriendsPlus/Page56/E2/13.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page56/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/15.jpg', input: true, answer: 'oard' },
        { url: 'img/FriendsPlus/Page56/E2/16.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page56/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/18.jpg', input: true, answer: 'ame' },
        { url: 'img/FriendsPlus/Page56/E2/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page56/E2/20.jpg' },

      ],

    ]
  },
}
export default json;