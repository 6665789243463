import LT3 from '../../components/ExcerciseTypes/LineTo/LT3';
import UI from '../../components/ExcerciseTypes/UseIt';

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB2-U1-P14-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page14/2.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB2-U1-P14-E2',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page14/3.jpg' },
      ],
    ]
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'WB2-U1-P14-E3',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page14/4.jpg' },
      ],
    ]
  },
  4: { // Exercise num
    unit: 'Unit 1',
    id: 'WB2-U1-P14-E4',
    audio: '/img/FriendsPlus/Page14/Audio/Track 002.mp3',
    video: '',
    component: LT3,
    numberAnser: 3,
    exerciseKey: 'img/FriendsPlus/Page14/E4/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Listen and draw the line.' }],
    questionImage: [],
    questions: [
      {
        right: [
          {
            id: 1,
            text: "/img/FriendsPlus/Page14/E4/1.png",
          },
          {
            id: 2,
            text: "/img/FriendsPlus/Page14/E4/3.png",
          },
          {
            id: 3,
            text: "/img/FriendsPlus/Page14/E4/2.png",
          },
          {
            id: 4,
            text: "/img/FriendsPlus/Page14/E4/4.png",
          },
          {
            id: 5,
            text: "/img/FriendsPlus/Page14/E4/3.png",
          },
          {
            id: 6,
            text: "/img/FriendsPlus/Page14/E4/5.png",
          },
        ],
        left: [
          {
            id: 1,
            text: "/img/FriendsPlus/Page14/E4/0.png",
          },
        ],
        answer: [
          {
            right: 2,
            left: 1,
          },
          {
            right: 3,
            left: 1,
          },
          {
            right: 5,
            left: 1,
          },
        ],
      },
    ]
  },
}

export default json;