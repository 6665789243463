
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {

  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB2-U4-P31-E1',
    audio: 'img/FriendsPlus/Page31/E1/Audio/Track 012.mp3',
    video: '',
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page31/E1/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page31/E1/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page31/E1/05.jpg' },
        { url: 'img/FriendsPlus/Page31/E1/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page31/E1/07.jpg', input: 1 },
      ],

      [
        { url: 'img/FriendsPlus/Page31/E1/08.jpg' },
        { url: 'img/FriendsPlus/Page31/E1/09.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page31/E1/10.jpg', input: 2, isCorrect: true },
      ],
      [
        { url: 'img/FriendsPlus/Page31/E1/11.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'WB2-U4-P31-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page31/E2/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page31/E2/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page31/E2/05.jpg' },
        { url: 'img/FriendsPlus/Page31/E2/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page31/E2/07.jpg' },
        { url: 'img/FriendsPlus/Page31/E2/08.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page31/E2/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page31/E2/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page31/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page31/E2/12.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page31/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page31/E2/14.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page31/E2/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page31/E2/16.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;