
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {


  1: { // Exercise num
    unit: 'Culture 3',
    id: 'WB2-C-P54-E1',
    audio: '',
    video: '',
    component: D1,

    fontSize: 30,
    exerciseKey: 'img/FriendsPlus/Page54/E1/Key/answerKeyP54E1.jpg',
    inputHeight: '100%',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page54/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page54/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page54/E1/3.jpg', input: true, answer: 'basketball' },
        { url: 'img/FriendsPlus/Page54/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page54/E1/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page54/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page54/E1/7.jpg', input: true, answer: 'badminton' },
        { url: 'img/FriendsPlus/Page54/E1/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page54/E1/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page54/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page54/E1/11.jpg', input: true, answer: 'hockey' },
        { url: 'img/FriendsPlus/Page54/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page54/E1/13.jpg' },
      ],

    ]
  },
  2: { // Exercise num
    unit: 'Culture 3',
    id: 'WB2-C-P54-E2',
    audio: '',
    video: '',
    component: D1,
    // exerciseKey: '',
    // recorder: true,
    hideBtnFooter: true,
    // isAllowSubmit: true,
    // isLargeInput: true,
    fontSize: 30,
    // exerciseKey: 'img/FriendsPlus/Page54/E2/Key/answerKeyP54E2.jpg',
    inputHeight: '100%',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page54/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page54/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page54/E2/3.jpg', input: true },
        { url: 'img/FriendsPlus/Page54/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page54/E2/5.jpg' },
      ],

    ]
  }
}
export default json;