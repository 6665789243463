import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import UI from '../../components/ExcerciseTypes/UseIt';
import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';

const json = {
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'WB2-U3-P29-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 6,
    exerciseKey: 'img/FriendsPlus/Page29/E3/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page29/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page29/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page29/E3/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page29/E3/4.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E3/5.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E3/6.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page29/E3/7.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page29/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page29/E3/9.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E3/10.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page29/E3/11.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page29/E3/12.jpg', input: 8, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page29/E3/14.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page29/E3/15.jpg', input: 10 },
        { url: 'img/FriendsPlus/Page29/E3/16.jpg', input: 11, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E3/17.jpg', input: 12, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E3/18.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'WB2-U3-P29-E4',
    audio: '',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page29/E4/Key/answerKey.png',
    recorder: true,
    fromAnchor: '100% 50%',
    toAnchor: '1% 50%',
    titleImage: 'img/FriendsPlus/Page29/E4/1.jpg',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page29/E4/2.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page29/E4/6.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page29/E4/10.jpg', isMatching: true, id: 3 },
        { url: 'img/FriendsPlus/Page29/E4/14.jpg', isMatching: true, id: 4 },
        { url: 'img/FriendsPlus/Page29/E4/18.jpg', isMatching: true, id: 5 },
        { url: 'img/FriendsPlus/Page29/E4/22.jpg', isMatching: true, id: 6 },
      ],
      [
        { url: 'img/FriendsPlus/Page29/E4/3.jpg' },
        { url: 'img/FriendsPlus/Page29/E4/7.jpg' },
        { url: 'img/FriendsPlus/Page29/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page29/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page29/E4/19.jpg' },
        { url: 'img/FriendsPlus/Page29/E4/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page29/E4/4.jpg', isMatching: true, id: 7 },
        { url: 'img/FriendsPlus/Page29/E4/8.jpg', isMatching: true, id: 8 },
        { url: 'img/FriendsPlus/Page29/E4/12.jpg', isMatching: true, id: 9 },
        { url: 'img/FriendsPlus/Page29/E4/16.jpg', isMatching: true, id: 10 },
        { url: 'img/FriendsPlus/Page29/E4/20.jpg', isMatching: true, id: 11 },
        { url: 'img/FriendsPlus/Page29/E4/24.jpg', isMatching: true, id: 12 },
      ],
      [
        { url: 'img/FriendsPlus/Page29/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page29/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page29/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page29/E4/17.jpg' },
        { url: 'img/FriendsPlus/Page29/E4/21.jpg' },
        { url: 'img/FriendsPlus/Page29/E4/25.jpg' },
      ],
    ],
    answers: ['1-7', '2-10', '3-8', '4-12', '5-9', '6-11'],
  },
  5: { // Exercise num
    unit: 'Unit 3',
    id: 'WB2-U3-P29-E5',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page29/E5/1.jpg' },
      ],
    ]
  },


}

export default json;