import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/UnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "WB2-U6-P44-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 4,
    fontSize: 30,
    inputHeight: "100%",
    isAllowSubmit: false,
    exerciseKey: "img/FriendsPlus/Page44/E1/Key/1.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page44/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page44/E1/2.jpg" },
        { url: "img/FriendsPlus/Page44/E1/3.jpg" },
        { url: "img/FriendsPlus/Page44/E1/4.jpg", input: 1 },
        { url: "img/FriendsPlus/Page44/E1/5.jpg", input: 2, isCorrect: true },
      ],
      [
        { url: "img/FriendsPlus/Page44/E1/6.jpg" },
        { url: "img/FriendsPlus/Page44/E1/7.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page44/E1/8.jpg", input: 4 },
        { url: "img/FriendsPlus/Page44/E1/9.jpg" },
        { url: "img/FriendsPlus/Page44/E1/10.jpg", input: 5 },
        { url: "img/FriendsPlus/Page44/E1/11.jpg", input: 6, isCorrect: true },
      ],
      [
        { url: "img/FriendsPlus/Page44/E1/12.jpg" },
        { url: "img/FriendsPlus/Page44/E1/13.jpg", input: 7, isCorrect: true },
        { url: "img/FriendsPlus/Page44/E1/14.jpg", input: 8 },
        { url: "img/FriendsPlus/Page44/E1/15.jpg" },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 6",
    id: "WB2-U6-P44-E2",
    audio: "",
    video: "",
    component: D1,
    fontSize: 41,
    inputHeight: "100%",
    isAllowSubmit: false,
    exerciseKey: "img/FriendsPlus/Page44/E2/Key/1.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page44/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page44/E2/2.jpg" },
        { url: "img/FriendsPlus/Page44/E2/3.jpg", input: true, answer: "w" },
        { url: "img/FriendsPlus/Page44/E2/4.jpg" },
        { url: "img/FriendsPlus/Page44/E2/5.jpg", input: true, answer: "x" },
        { url: "img/FriendsPlus/Page44/E2/6.jpg" },
        { url: "img/FriendsPlus/Page44/E2/7.jpg", input: true, answer: "y" },
        { url: "img/FriendsPlus/Page44/E2/8.jpg" },
        { url: "img/FriendsPlus/Page44/E2/9.jpg", input: true, answer: "z" },
        { url: "img/FriendsPlus/Page44/E2/10.jpg", input: true, answer: "z" },
        { url: "img/FriendsPlus/Page44/E2/11.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page44/E2/12.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 6",
    id: "WB2-U6-P44-E3",
    audio: "",
    video: "",
    component: D1,
    fontSize: 30,
    inputHeight: "100%",
    isAllowSubmit: false,
    exerciseKey: "img/FriendsPlus/Page44/E3/Key/1.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page44/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page44/E3/2.jpg" },
        { url: "img/FriendsPlus/Page44/E3/3.jpg", input: true, answer: "z" },
        { url: "img/FriendsPlus/Page44/E3/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page44/E3/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page44/E3/6.jpg" },
        { url: "img/FriendsPlus/Page44/E3/7.jpg", input: true, answer: "x" },
        { url: "img/FriendsPlus/Page44/E3/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page44/E3/9.jpg" }],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 6",
    id: "WB2-U6-P44-E4",
    audio: "",
    video: "",
    component: D1,
    fontSize: 30,
    inputHeight: "100%",
    isAllowSubmit: false,
    exerciseKey: "img/FriendsPlus/Page44/E4/Key/1.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page44/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page44/E4/2.jpg" },
        { url: "img/FriendsPlus/Page44/E4/3.jpg", input: true, answer: "r" },
        { url: "img/FriendsPlus/Page44/E4/4.jpg" },
        { url: "img/FriendsPlus/Page44/E4/5.jpg", input: true, answer: "s" },
        { url: "img/FriendsPlus/Page44/E4/6.jpg" },
        { url: "img/FriendsPlus/Page44/E4/7.jpg", input: true, answer: "t" },
        { url: "img/FriendsPlus/Page44/E4/8.jpg" },
        { url: "img/FriendsPlus/Page44/E4/9.jpg", input: true, answer: "u" },
        { url: "img/FriendsPlus/Page44/E4/10.jpg" },
        { url: "img/FriendsPlus/Page44/E4/11.jpg", input: true, answer: "v" },
        { url: "img/FriendsPlus/Page44/E4/12.jpg", input: true, answer: "w" },
        { url: "img/FriendsPlus/Page44/E4/13.jpg" },
        { url: "img/FriendsPlus/Page44/E4/14.jpg", input: true, answer: "x" },
        { url: "img/FriendsPlus/Page44/E4/15.jpg" },
        { url: "img/FriendsPlus/Page44/E4/16.jpg", input: true, answer: "y" },
        { url: "img/FriendsPlus/Page44/E4/17.jpg", input: true, answer: "z" },
        { url: "img/FriendsPlus/Page44/E4/18.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page44/E4/19.jpg" }],
    ],
  },
};

export default json;
