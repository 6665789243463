import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Culture",
    id: "WB2-C-P50-E1",
    audio: "",
    video: "",
    isAllowSubmit: true,
    component: UnderLine,
    hideBtnFooter: true,

    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page50/E1/1.jpg" }],
    ],
  },
};
export default json;
