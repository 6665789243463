/* eslint-disable no-undef */
import React , {useState} from "react";
import moment from 'moment';
import LineTo from 'react-lineto';
import { useDrop } from "react-dnd";
import update from "immutability-helper";
import { Container, Row, Col , Modal , ModalBody } from "reactstrap";
import { Card } from "./Card.js";
import { ItemTypes } from "./ItemTypes";
import FooterModal from "../../../FooterModal/index.js";
import TitleQuestion from "../../../TitleQuestion/index.js";
import Firework from '../../../ResultsAndEffects/fireworks.js';
import Animate from '../../../Animate';
import styles from './styles.module.css';

const LT3 = (props) => {
  const [cardsRight, setCardsRight] = React.useState([]);

  const [cardsLeft, setCardsLeft] = React.useState([]);

  const [state, setState] = React.useState({
    star: 0,
    answer: null,
    isDoing: true,
    recordURL: null,
    matchedPairs: [],
    booleanArray: [],
    lineVisible: true,
    selectedLeft: null,
    selectedRight: null,
    isDisabledSubmit: true,
  });

  const [modal, setModal] = useState(false);

  React.useEffect(() => {
    if (!state.lineVisible) {
      setState((prevState) => ({ ...prevState, lineVisible: true }));
    }
  }, [state.lineVisible]);

  React.useEffect(() => {
    const cardsLeft = JSON.parse(JSON.stringify(props.questions[0].left));
    const cardsRight = JSON.parse(JSON.stringify(props.questions[0].right));
    const answer = props.questions[0].answer;
    setCardsRight(cardsRight);
    setCardsLeft(cardsLeft);
    if (state.matchedPairs.length === props.numberAnser) {
      state.isDisabledSubmit = false;
    }
    setState((prevState) => ({ ...prevState, answer }))
  }, [props, state, state.matchedPairs.length])

  const findCardRight = React.useCallback((id) => {
    const card = cardsRight.filter((c) => `${c.id}` === id)[0];
    return {
      card,
      index: cardsRight.indexOf(card),
    };
  },
    [cardsRight]
  );

  const moveCardRight = React.useCallback((id, atIndex) => {
    const { card, index } = findCardRight(id);
    setCardsRight(
      update(cardsRight, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      })
    );
  }, [cardsRight, findCardRight]);

  const [, dropRight] = useDrop({ accept: ItemTypes.CARD });

  // Card left
  const findCardLeft = React.useCallback(
    (id) => {
      const card = cardsLeft.filter((c) => `${c.id}` === id)[0];
      return {
        card,
        index: cardsLeft.indexOf(card),
      };
    },
    [cardsLeft]
  );

  const moveCardLeft = React.useCallback(
    (id, atIndex) => {
      const { card, index } = findCardLeft(id);
      setCardsLeft(
        update(cardsLeft, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        })
      );
    },
    [cardsLeft, findCardLeft]
  );

  // const [, dropLeft] = useDrop({ accept: ItemTypes.CARD });

  const checkAnswer = React.useCallback(() => {
    const { matchedPairs, answer } = state;

    matchedPairs.forEach((item, index) => {
      const isCorrect = answer.findIndex((x) => x.left === item.left.id && x.right === item.right.id) > -1;
      matchedPairs[index].isCorrect = isCorrect;
      const abc = matchedPairs[index].isCorrect;
      state.booleanArray.push(abc);
    });

    let correctAnswer = 0;
    state.booleanArray.forEach((item, index) => {
      if (item) {
        correctAnswer++
      }
    })
    const percent = parseInt(correctAnswer / state.booleanArray.length * 100)
    const resultString = `${correctAnswer}/${state.booleanArray.length}`
    const star = percent / 20;

    const params = {
      score: percent,
      unit: props.unit,
      results: resultString,
      exerciseId: props.id,
      sreatedDate: moment().format(),
      studentAnswer: '',
    }
    props.postAnswerToApi(params)

    onModal();

    setState((prevState) => ({
      ...prevState,
      isDoing: false,
      matchedPairs,
      isDisabledSubmit: true,
      star,
    }))
  }, [props,state]);

  
  const onModal = React.useCallback(() => {
    setModal(true)
    setTimeout(() => {
      offModal();
    }, 5500);
  }, []);

  const offModal = React.useCallback(() => {
    setModal(false)
  }, []);

  const tryAgain = React.useCallback(() => {
    const cardsLeft = JSON.parse(JSON.stringify(props.questions[0].left));
    const cardsRight = JSON.parse(JSON.stringify(props.questions[0].right));
    setCardsRight(cardsRight);
    setCardsLeft(cardsLeft);

    setState((prevState) => ({
      ...prevState,
      isDoing: true,
      matchedPairs: [],
      booleanArray: [],
      selectedLeft: null,
      selectedRight: null,
      isDisabledSubmit: true,
    }));
  }, [props.questions]);

  const onClickItem = React.useCallback((item, index, side) => () => {
    if (!state.isDoing) return;

    setState((prevState) => {
      const stateModel = { [`selected${side}`]: item };

      if ((side === 'Left' && prevState.selectedRight) || (side === 'Right' && prevState.selectedLeft)) {
        stateModel.matchedPairs = JSON.parse(JSON.stringify(prevState.matchedPairs));
        const left = prevState.selectedLeft || stateModel.selectedLeft;
        const right = prevState.selectedRight || stateModel.selectedRight;
        // const leftIndex = stateModel.matchedPairs.findIndex((x) => x.left.id === left.id);
        const rightIndex = stateModel.matchedPairs.findIndex((x) => {
          if (right.id % 2 === 0 && (right.id - 1) === x.right.id) {
            return true;
          }

          if (right.id % 2 !== 0 && (right.id + 1) === x.right.id) {
            return true;
          }

          return right.id === x.right.id;
        });

        const itemModel = { left, right };

        if (rightIndex > -1) {
          stateModel.matchedPairs.splice(rightIndex, 1, itemModel);
        } else {
          stateModel.matchedPairs.push({ left, right });
        }

        stateModel.selectedLeft = null;
        stateModel.selectedRight = null;
        stateModel.lineVisible = false;
      }

      return ({
        ...prevState,
        ...stateModel,
      });
    });
  }, [state.isDoing]);

  const renderLine = React.useCallback((item, index) => {
    let lineColor = 'orange';

    const isEven = (item.right.id - 1) % 2 === 0;

    const fromTopPercent = isEven ? '0%' : '100%';
    const toTopPercent = isEven ? '100%' : '0%';

    let fromLeftPercent = '50%';
    let toLeftPercent = '50%';

    if (!isEven) {
      if (item.right.id === 2) {
        toLeftPercent = '100%';
        fromLeftPercent = '0%';
      } else if (item.right.id === 6) {
        toLeftPercent = '0%';
        fromLeftPercent = '100%';
      }
    } else if (isEven) {
      if (item.right.id === 1) {
        toLeftPercent = '100%';
        fromLeftPercent = '0%';
      } else if (item.right.id === 5) {
        toLeftPercent = '0%';
        fromLeftPercent = '100%';
      }
    }

    const fromAnchor = `${fromLeftPercent} ${fromTopPercent}`;
    const toAnchor = `${toLeftPercent} ${toTopPercent}`;

    if (typeof item.isCorrect === 'boolean') {
      lineColor = item.isCorrect ? 'green' : undefined;
    }

    return (
      <LineTo
        key={index}
        from={`left-${item.left.id}`}
        to={`right-${item.right.id}`}
        zIndex={1100}
        borderWidth={2}
        toAnchor={toAnchor}
        fromAnchor={fromAnchor}
        borderColor={lineColor}
        className={styles.line}
        within="cardContainer"
      />
    );
  }, []);

  const renderRight = React.useCallback((item, index, even) => {
    if (even && index % 2 === 0) return null;
    if (!even && index % 2 !== 0) return null;

    let customStyles = {};

    const lineIndex = state.matchedPairs.findIndex((x) => x.right.id === item.id);

    if (lineIndex > -1 || state.selectedRight?.id === item.id) {
      customStyles = {
        borderWidth: 2,
        // borderRadius: 12,
        borderStyle: 'solid',
        borderColor: 'orange',
      };
    }

    return (
      <div
        style={{
          display: 'flex',
          margin: 72,
          justifyContent: 'center', alignItems: 'center'
        }}
        className={`right-${item.id}`}
        onClick={onClickItem(item, index, 'Right')}
      >
        <Card
          key={item.id}
          id={`${item.id}`}
          text={item.text}
          moveCard={moveCardRight}
          findCard={findCardRight}
          style={customStyles}
        />
      </div>
    )
  }, [state.matchedPairs, state.selectedRight?.id, onClickItem, moveCardRight, findCardRight]);

  const renderLeft = React.useCallback((item, index) => {
    let customStyles = {};

    const lineIndex = state.matchedPairs.findIndex((x) => x.left.id === item.id);

    if (lineIndex > -1 || state.selectedLeft?.id === item.id) {
      customStyles = {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'orange',
      };
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center', alignItems: 'center'
        }}
        onClick={onClickItem(item, index, 'Left')}
      >
        <Card
          key={item.id}
          id={`${item.id}`}
          text={item.text}
          moveCard={moveCardLeft}
          findCard={findCardLeft}
          style={customStyles}
          className={`left-${item.id}`}
        />
      </div>
    )
  }, [state.matchedPairs, state.selectedLeft?.id, onClickItem, moveCardLeft, findCardLeft]);

  if (!cardsLeft || !cardsRight) return null

  return (
    <Container id="gfx_holder" className="fluid">
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <TitleQuestion titleQuestion={props.titleQuestion} />
      </Row>
      <Row className="d-flex align-items-center flex-row" style={{ minWidth: 1000 }}>
        {/* <Col style={{ marginLeft: 10 }} ref={dropLeft}>
          {cardsLeft.map(renderLeft)}
        </Col> */}
         <Modal centered isOpen={modal} style={{ maxWidth: 640, }}>
          <ModalBody>
            {/* {renderContent} */}
            {(state.star >= 1) && (
              <Firework />
            )}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 240 }}>
              <Animate
                resultString={state.star}
              />
            </div>
          </ModalBody>
        </Modal>
        <Col ref={dropRight} className="cardContainer">
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 14 }}>
            {cardsRight.map((item, index) => renderRight(item, index, false))}
          </div>
          {cardsLeft.map(renderLeft)}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 14 }}>
            {cardsRight.map((item, index) => renderRight(item, index, true))}
          </div>
        </Col>

        {state.lineVisible && state.matchedPairs.map(renderLine)}
      </Row>
      <FooterModal
        onRetry={tryAgain}
        onSubmit={checkAnswer}
        audioUrl={props.audio}
        result={state?.booleanArray}
        exerciseKey={props.exerciseKey}
        isDisabledRetry={state.isDoing}
        isDisabledSubmit={state.isDisabledSubmit}
        hideBtnFooter={false}
      />
    </Container>
  );
};
export default LT3;
