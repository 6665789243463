import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import UI from '../../components/ExcerciseTypes/UseIt';

const json = {

  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB2-U4-P32-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: 'img/FriendsPlus/Page32/E1/title.jpg',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page32/E1/1.jpg' },
      ],
    ]
  },



  2: { // Exercise num
    unit: 'Unit 4',
    id: 'WB2-U4-P32-E2',
    audio: '',
    // video: '',
    inputHeight: 35,
    fontSize: 34,
    typeInput: 'center',
    exerciseKey: 'img/FriendsPlus/Page32/E2/Key/Key.png',
    component: D1,
    //titleImage: '',
    //titleQuestion: [{ num: '2', title: 'Count and write the number', color: "#253E8E" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page32/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page32/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page32/E2/3.jpg', input: true, answer: "w" },
        { url: 'img/FriendsPlus/Page32/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page32/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page32/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page32/E2/7.jpg', input: true, answer: "x" },
        { url: 'img/FriendsPlus/Page32/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page32/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page32/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page32/E2/11.jpg', input: true, answer: "x" },
        { url: 'img/FriendsPlus/Page32/E2/12.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 4',
    id: 'WB2-U4-P32-E3',
    audio: 'img/FriendsPlus/Page32/E3/Audio/Track 013.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page32/E3/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page32/E3/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E3/05.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page32/E3/07.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/08.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page32/E3/09.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/10.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page32/E3/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/13.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page32/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/15.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page32/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/17.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page32/E3/18.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}


export default json;