import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB2-U5-P36-E1',
    audio: '',
    video: '',
    typeInput: 'center',
    component: D1,
    inputHeight: '100%',
    fontSize: 40,
    exerciseKey: 'img/FriendsPlus/Page36/E1/Key/answerKeyP36E1.jpg',

    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page36/E1/1.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page36/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/3.jpg', input: true, answer: '4' },
        { url: 'img/FriendsPlus/Page36/E1/4.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page36/E1/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E1/6.jpg', },
        { url: 'img/FriendsPlus/Page36/E1/7.jpg', input: true, answer: '3' },
        { url: 'img/FriendsPlus/Page36/E1/8.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page36/E1/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/13.jpg', input: true, answer: '2' },
        { url: 'img/FriendsPlus/Page36/E1/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E1/15.jpg' },
      ]

    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB2-U5-P36-E2',
    audio: '',
    video: '',
    component: D1,
    // typeInput : 'center',
    inputHeight: '100%',
    fontSize: 27,
    exerciseKey: 'img/FriendsPlus/Page36/E2/Key/answerKeyP36E2.jpg',

    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page36/E2/1.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/3.jpg', input: true, answer: 'pool' },
        { url: 'img/FriendsPlus/Page36/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/5.jpg', input: true, answer: 'slide' },
        { url: 'img/FriendsPlus/Page36/E2/6.jpg', },

      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/7.jpg' },


      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/9.jpg', input: true, answer: 'seesaw' },
        { url: 'img/FriendsPlus/Page36/E2/10.jpg', },
        { url: 'img/FriendsPlus/Page36/E2/11.jpg', input: true, answer: 'frisbee' },
        { url: 'img/FriendsPlus/Page36/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/13.jpg', input: true, answer: 'tree' },
        { url: 'img/FriendsPlus/Page36/E2/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E2/15.jpg' },

      ],

    ],
  },

}
export default json;