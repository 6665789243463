
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import LT2_1 from '../../components/ExcerciseTypes/LineTo/LT2_1';

const json = {
  1: { // Exercise num
     unit : 'Unit 5',
    id :  'WB2-U5-P40-E1',
    audio: '',
    video: '',
    component: LT2_1,
    exerciseKey: 'img/FriendsPlus/Page40/E1/Key/answerKeyP40E1.jpg',
    // recorder: true,
    toAnchor:'50% -1%',
    fromAnchor:'50% 100%',
    // titleImage: 'img/FriendsPlus/Page4/E1/1.jpg',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page40/E1/1.jpg'},
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page40/E1/2.jpg', },
        { url: 'img/FriendsPlus/Page40/E1/3.jpg',isMatching: true, id: 1},
        { url: 'img/FriendsPlus/Page40/E1/4.jpg'},
        { url: 'img/FriendsPlus/Page40/E1/5.jpg', isMatching: true, id: 2},
        { url: 'img/FriendsPlus/Page40/E1/6.jpg'},
        { url: 'img/FriendsPlus/Page40/E1/7.jpg', isMatching: true, id: 3},
        { url: 'img/FriendsPlus/Page40/E1/8.jpg'},
        { url: 'img/FriendsPlus/Page40/E1/9.jpg', isMatching: true, id: 4},
        { url: 'img/FriendsPlus/Page40/E1/10.jpg'},
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page40/E1/11.jpg'},

      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page40/E1/11.jpg'},

      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page40/E1/12.jpg', isMatching: true, id: 5},
        { url: 'img/FriendsPlus/Page40/E1/13.jpg', isMatching: true, id: 6},
        { url: 'img/FriendsPlus/Page40/E1/14.jpg', isMatching: true, id: 7},
        { url: 'img/FriendsPlus/Page40/E1/15.jpg', isMatching: true, id: 8},
      ],
      
      
    ],
    answers: ['1-5','2-6','3-8','4-7'],
  },


// match three column

  // 1: { // Exercise num
   unit : 'Unit 5',
  //   audio: '',
  //   video: '',
  //   component: LT4,
  //   exerciseKey: 'img/FriendsPlus/Page40/E1_1/Key/answerKeyP40E1.jpg',
  //   // recorder: true,
  //   toAnchor:'50% -1%',
  //   fromAnchor:'50% 100%',
  //   titleImage: 'img/FriendsPlus/Page40/E1_1/1.jpg',
  //   questionImage: [ // Row
  //     // [
  //     //   // Column1
  //     //   { url: 'img/FriendsPlus/Page40/E1_1/1.jpg'},
  //     // ],
  //     [
  //       // Column2
  //       { url: 'img/FriendsPlus/Page40/E1_1/2.jpg', },
  //       { url: 'img/FriendsPlus/Page40/E1_1/3.jpg',isMatching: true, id: 1},
  //       { url: 'img/FriendsPlus/Page40/E1_1/4.jpg'},
  //       { url: 'img/FriendsPlus/Page40/E1_1/5.jpg', isMatching: true, id: 2},
  //       { url: 'img/FriendsPlus/Page40/E1_1/6.jpg'},
  //       { url: 'img/FriendsPlus/Page40/E1_1/7.jpg', isMatching: true, id: 3},
  //       { url: 'img/FriendsPlus/Page40/E1_1/8.jpg'},
  //       { url: 'img/FriendsPlus/Page40/E1_1/9.jpg', isMatching: true, id: 4},
  //       { url: 'img/FriendsPlus/Page40/E1_1/10.jpg'},
  //     ],
  //     [
  //       // Column3
  //       { url: 'img/FriendsPlus/Page40/E1_1/11.jpg'},

  //     ],
     
  //     [
  //       // Column2
  //       { url: 'img/FriendsPlus/Page40/E1_1/12.jpg', },
  //       { url: 'img/FriendsPlus/Page40/E1_1/13.jpg',isMatching: true, id: 5},
  //       { url: 'img/FriendsPlus/Page40/E1_1/14.jpg'},
  //       { url: 'img/FriendsPlus/Page40/E1_1/15.jpg', isMatching: true, id: 6},
  //       { url: 'img/FriendsPlus/Page40/E1_1/16.jpg'},
  //       { url: 'img/FriendsPlus/Page40/E1_1/17.jpg', isMatching: true, id: 7},
  //       { url: 'img/FriendsPlus/Page40/E1_1/18.jpg'},
  //       { url: 'img/FriendsPlus/Page40/E1_1/19.jpg', isMatching: true, id: 8},
  //       { url: 'img/FriendsPlus/Page40/E1_1/20.jpg'},
  //     ],
     
  //     [
  //       // Column3
  //       { url: 'img/FriendsPlus/Page40/E1_1/21.jpg'},

  //     ],
   
  //     [
  //       // Column4
  //       { url: 'img/FriendsPlus/Page40/E1_1/22.jpg', isMatching: true, id: 9},
  //       { url: 'img/FriendsPlus/Page40/E1_1/23.jpg', isMatching: true, id: 10},
  //       { url: 'img/FriendsPlus/Page40/E1_1/24.jpg', isMatching: true, id: 11},
  //       { url: 'img/FriendsPlus/Page40/E1_1/25.jpg', isMatching: true, id: 12},
  //     ],
      
      
  //   ],
  //   answers: ['1-6','6-10','2-5','5-9','3-8','4-7','7-12','8-11',],
  //   // answers: ['1-5-9','2-6-10','3-8-11','4-7-12'],
  // },







  2: { // Exercise num
     unit : 'Unit 5',
    id :  'WB2-U5-P40-E2',
    audio: '',
    video: '',
    
    component: D1,
    inputHeight: '100%',
    fontSize: 40,
    exerciseKey: 'img/FriendsPlus/Page40/E2/Key/answerKeyP40E2.jpg',
    
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page40/E2/1.jpg' },
       
      ],
      [
        { url: 'img/FriendsPlus/Page40/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page40/E2/3.jpg', input: true, answer:'r'  },
        { url: 'img/FriendsPlus/Page40/E2/4.jpg' },
      
      ],
      [
        { url: 'img/FriendsPlus/Page40/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page40/E2/6.jpg', input: true, answer:'s'    },
        { url: 'img/FriendsPlus/Page40/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page40/E2/8.jpg', input: true, answer:'t'    },
        { url: 'img/FriendsPlus/Page40/E2/9.jpg' },
      
      ],
      [
        { url: 'img/FriendsPlus/Page40/E2/10.jpg' },
       
      ],
     
    ],
  },
  3: { // Exercise num
     unit : 'Unit 5',
    id :  'WB2-U5-P40-E3',
    audio: 'img/FriendsPlus/Page40/E3/Audio/audio.e3.p40.mp3',
    video: '',
    typeInput:'center',
    component: D1,
    inputHeight: '100%',
    fontSize: 27,
    exerciseKey: 'img/FriendsPlus/Page40/E3/Key/answerKeyP40E3.jpg',
    
    questionImage: [ // Row
        [
            { url: 'img/FriendsPlus/Page40/E3/1.1.jpg' },
            { url: 'img/FriendsPlus/Page40/E3/1.2.jpg', audioUrl: 'img/FriendsPlus/Page40/E3/Audio/tieude.e3.p40.mp3' },
            { url: 'img/FriendsPlus/Page40/E3/1.3.jpg'},
           
          ],
      [
        { url: 'img/FriendsPlus/Page40/E3/1.jpg'},
       
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/2.jpg'},
        { url: 'img/FriendsPlus/Page40/E3/3.jpg', input: true, answer:'t'  },
        { url: 'img/FriendsPlus/Page40/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/5.jpg',input: true, answer:'t'},
        { url: 'img/FriendsPlus/Page40/E3/6.jpg',     },
        { url: 'img/FriendsPlus/Page40/E3/7.jpg' , input: true, answer:'r' },
        { url: 'img/FriendsPlus/Page40/E3/8.jpg'   },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/9.jpg' },
       
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/10.jpg',  },
        { url: 'img/FriendsPlus/Page40/E3/11.jpg' ,input: true, answer:'t' },
        { url: 'img/FriendsPlus/Page40/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/13.jpg', input: true, answer:'r'    },
        { url: 'img/FriendsPlus/Page40/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/15.jpg', input: true, answer:'s'    },
        { url: 'img/FriendsPlus/Page40/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/17.jpg', input: true, answer:'u'    },
        { url: 'img/FriendsPlus/Page40/E3/18.jpg' },
       
      
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/19.jpg' },
       
      ],
     
    ],
  },

}
export default json;