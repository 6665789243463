import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "WB2-U6-P46-E1",
    audio: "",
    video: "",
    component: D1,
    fontSize: 26,
    inputHeight: "100%",
    isAllowSubmit: false,
    typeInput: "center",
    exerciseKey: "img/FriendsPlus/Page46/E1/Key/1.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page46/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page46/E1/2.jpg" },
        { url: "img/FriendsPlus/Page46/E1/3.jpg", input: true, answer: "o" },
        { url: "img/FriendsPlus/Page46/E1/4.jpg" },
        { url: "img/FriendsPlus/Page46/E1/5.jpg", input: true, answer: "p" },
        { url: "img/FriendsPlus/Page46/E1/6.jpg" },
        { url: "img/FriendsPlus/Page46/E1/7.jpg", input: true, answer: "q" },
        { url: "img/FriendsPlus/Page46/E1/8.jpg" },
        { url: "img/FriendsPlus/Page46/E1/9.jpg", input: true, answer: "r" },
        { url: "img/FriendsPlus/Page46/E1/10.jpg" },
        { url: "img/FriendsPlus/Page46/E1/11.jpg", input: true, answer: "s" },
        { url: "img/FriendsPlus/Page46/E1/12.jpg" },
        { url: "img/FriendsPlus/Page46/E1/13.jpg", input: true, answer: "t" },
        { url: "img/FriendsPlus/Page46/E1/14.jpg" },
        { url: "img/FriendsPlus/Page46/E1/15.jpg", input: true, answer: "u" },
        { url: "img/FriendsPlus/Page46/E1/16.jpg" },
        { url: "img/FriendsPlus/Page46/E1/17.jpg", input: true, answer: "v" },
        { url: "img/FriendsPlus/Page46/E1/18.jpg" },
        { url: "img/FriendsPlus/Page46/E1/19.jpg", input: true, answer: "w" },
        { url: "img/FriendsPlus/Page46/E1/20.jpg" },
        { url: "img/FriendsPlus/Page46/E1/21.jpg", input: true, answer: "x" },
        { url: "img/FriendsPlus/Page46/E1/22.jpg" },
        { url: "img/FriendsPlus/Page46/E1/23.jpg", input: true, answer: "y" },
        { url: "img/FriendsPlus/Page46/E1/24.jpg" },
        { url: "img/FriendsPlus/Page46/E1/25.jpg", input: true, answer: "z" },
        { url: "img/FriendsPlus/Page46/E1/26.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page46/E1/27.jpg" }],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 6",
    id: "WB2-U6-P46-E2",
    audio: "",
    video: "",
    component: D1,
    fontSize: 42,
    inputHeight: "100%",
    typeInput: "center",
    isAllowSubmit: false,
    exerciseKey: "img/FriendsPlus/Page46/E2/Key/1.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page46/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page46/E2/2.jpg" },
        { url: "img/FriendsPlus/Page46/E2/3.jpg", input: true, answer: "s" },
        { url: "img/FriendsPlus/Page46/E2/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page46/E2/5.jpg" },
        { url: "img/FriendsPlus/Page46/E2/6.jpg", input: true, answer: "o" },
        { url: "img/FriendsPlus/Page46/E2/7.jpg" },
        { url: "img/FriendsPlus/Page46/E2/8.jpg", input: true, answer: "y" },
        { url: "img/FriendsPlus/Page46/E2/9.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page46/E2/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page46/E2/11.jpg" },
        { url: "img/FriendsPlus/Page46/E2/12.jpg", input: true, answer: "x" },
        { url: "img/FriendsPlus/Page46/E2/13.jpg" },
        { url: "img/FriendsPlus/Page46/E2/14.jpg", input: true, answer: "u" },
        { url: "img/FriendsPlus/Page46/E2/15.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page46/E2/16.jpg" },
        { url: "img/FriendsPlus/Page46/E2/17.jpg", input: true, answer: "p" },
        { url: "img/FriendsPlus/Page46/E2/18.jpg" },
        { url: "img/FriendsPlus/Page46/E2/19.jpg", input: true, answer: "t" },
        { url: "img/FriendsPlus/Page46/E2/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page46/E2/21.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 6",
    id: "WB2-U6-P46-E3",
    audio: "",
    video: "",
    component: D1,
    fontSize: 32,
    inputHeight: "100%",
    typeInput: "center",
    isAllowSubmit: false,
    exerciseKey: "img/FriendsPlus/Page46/E3/Key/1.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page46/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page46/E3/2.jpg" },
        { url: "img/FriendsPlus/Page46/E3/3.jpg", input: true, answer: "q" },
        { url: "img/FriendsPlus/Page46/E3/4.jpg" },
        { url: "img/FriendsPlus/Page46/E3/5.jpg", input: true, answer: "z" },
        { url: "img/FriendsPlus/Page46/E3/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page46/E3/7.jpg" },
        { url: "img/FriendsPlus/Page46/E3/8.jpg", input: true, answer: "s" },
        { url: "img/FriendsPlus/Page46/E3/9.jpg" },
        { url: "img/FriendsPlus/Page46/E3/10.jpg", input: true, answer: "p" },
        { url: "img/FriendsPlus/Page46/E3/11.jpg" },
        { url: "img/FriendsPlus/Page46/E3/12.jpg", input: true, answer: "v" },
        { url: "img/FriendsPlus/Page46/E3/13.jpg" },
        { url: "img/FriendsPlus/Page46/E3/14.jpg", input: true, answer: "n" },
        { url: "img/FriendsPlus/Page46/E3/15.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page46/E3/16.jpg" },
        { url: "img/FriendsPlus/Page46/E3/17.jpg", input: true, answer: "z" },
        { url: "img/FriendsPlus/Page46/E3/18.jpg", input: true, answer: "z" },
        { url: "img/FriendsPlus/Page46/E3/19.jpg" },
        { url: "img/FriendsPlus/Page46/E3/20.jpg", input: true, answer: "z" },
        { url: "img/FriendsPlus/Page46/E3/21.jpg", input: true, answer: "z" },
        { url: "img/FriendsPlus/Page46/E3/22.jpg" },
        { url: "img/FriendsPlus/Page46/E3/23.jpg", input: true, answer: "t" },
        { url: "img/FriendsPlus/Page46/E3/24.jpg" },
        { url: "img/FriendsPlus/Page46/E3/25.jpg", input: true, answer: "r" },
        { url: "img/FriendsPlus/Page46/E3/26.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page46/E3/27.jpg" },
        { url: "img/FriendsPlus/Page46/E3/28.jpg", input: true, answer: "r" },
        { url: "img/FriendsPlus/Page46/E3/29.jpg" },
      ],
    ],
  },
};

export default json;
