import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "WB2-U6-P45-E1",
    audio: "",
    video: "",
    component: D1,
    fontSize: 30,
    inputHeight: "100%",
    isAllowSubmit: false,
    exerciseKey: "img/FriendsPlus/Page45/E1/Key/1.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page45/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page45/E1/2.jpg" },
        { url: "img/FriendsPlus/Page45/E1/3.jpg", input: true, answer: "12" },
        { url: "img/FriendsPlus/Page45/E1/4.jpg" },
        { url: "img/FriendsPlus/Page45/E1/5.jpg", input: true, answer: "14" },
        { url: "img/FriendsPlus/Page45/E1/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page45/E1/7.jpg" },
        {
          url: "img/FriendsPlus/Page45/E1/8.jpg",
          input: true,
          answer: "thirteen",
        },
        { url: "img/FriendsPlus/Page45/E1/9.jpg" },
        {
          url: "img/FriendsPlus/Page45/E1/10.jpg",
          input: true,
          answer: "fifteen",
        },
        { url: "img/FriendsPlus/Page45/E1/11.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page45/E1/12.jpg" }],
      [
        { url: "img/FriendsPlus/Page45/E1/13.jpg" },
        { url: "img/FriendsPlus/Page45/E1/14.jpg", input: true, answer: "16" },
        { url: "img/FriendsPlus/Page45/E1/15.jpg" },
        { url: "img/FriendsPlus/Page45/E1/16.jpg", input: true, answer: "18" },
        { url: "img/FriendsPlus/Page45/E1/17.jpg" },
        { url: "img/FriendsPlus/Page45/E1/18.jpg", input: true, answer: "20" },
        { url: "img/FriendsPlus/Page45/E1/19.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page45/E1/20.jpg" },
        {
          url: "img/FriendsPlus/Page45/E1/21.jpg",
          input: true,
          answer: "seventeen",
        },
        { url: "img/FriendsPlus/Page45/E1/22.jpg" },
        {
          url: "img/FriendsPlus/Page45/E1/23.jpg",
          input: true,
          answer: "nineteen",
        },
        { url: "img/FriendsPlus/Page45/E1/24.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page45/E1/25.jpg" }],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 6",
    id: "WB2-U6-P45-E2",
    audio: "",
    video: "",
    component: D1,
    fontSize: 29,
    inputHeight: "100%",
    isAllowSubmit: false,
    exerciseKey: "img/FriendsPlus/Page45/E2/Key/1.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page45/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page45/E2/2.jpg" },
        { url: "img/FriendsPlus/Page45/E2/3.jpg", input: true, answer: "19" },
        { url: "img/FriendsPlus/Page45/E2/4.jpg" },
        { url: "img/FriendsPlus/Page45/E2/5.jpg", input: true, answer: "17" },
        { url: "img/FriendsPlus/Page45/E2/6.jpg" },
        { url: "img/FriendsPlus/Page45/E2/7.jpg", input: true, answer: "13" },
        { url: "img/FriendsPlus/Page45/E2/8.jpg" },
        { url: "img/FriendsPlus/Page45/E2/9.jpg", input: true, answer: "15" },
        { url: "img/FriendsPlus/Page45/E2/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page45/E2/11.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 6",
    id: "WB2-U6-P45-E3",
    audio: "",
    video: "",
    component: D1,
    fontSize: 29,
    inputHeight: "100%",
    isAllowSubmit: false,
    exerciseKey: "img/FriendsPlus/Page45/E3/Key/1.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page45/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page45/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page45/E3/3.jpg",
          input: true,
          answer: "fourteen",
        },
        { url: "img/FriendsPlus/Page45/E3/4.jpg" },
        {
          url: "img/FriendsPlus/Page45/E3/5.jpg",
          input: true,
          answer: "fifteen",
        },
        { url: "img/FriendsPlus/Page45/E3/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page45/E3/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page45/E3/8.jpg" },
        {
          url: "img/FriendsPlus/Page45/E3/9.jpg",
          input: true,
          answer: "twenty",
        },
        { url: "img/FriendsPlus/Page45/E3/10.jpg" },
        {
          url: "img/FriendsPlus/Page45/E3/11.jpg",
          input: true,
          answer: "twelve",
        },
        { url: "img/FriendsPlus/Page45/E3/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page45/E3/13.jpg" }],
    ],
  },
};

export default json;
