
import LT2 from '../../components/ExcerciseTypes/LineTo/LT2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    1: { // Exercise num
      unit : 'Unit 4',
      id :  'WB2-U4-P35-E1',
        audio: '',
        video: '',
        component: LT2,
        exerciseKey: 'img/FriendsPlus/Page35/E1/Key/1.png',
        recorder: true,
        toAnchor:'50% -1%',
        fromAnchor:'50% 100%',
        // titleImage: 'img/FriendsPlus/Page4/E1/1.jpg',
        questionImage: [ // Row
          [
            // Column1
            { url: 'img/FriendsPlus/Page35/E1/1.jpg'},
          ],
          [
            // Column2
            { url: 'img/FriendsPlus/Page35/E1/2.jpg', isMatching: true, id: 1},
            { url: 'img/FriendsPlus/Page35/E1/3.jpg', isMatching: true, id: 2},
            { url: 'img/FriendsPlus/Page35/E1/4.jpg', isMatching: true, id: 3},
            { url: 'img/FriendsPlus/Page35/E1/5.jpg', isMatching: true, id: 4},
          ],
          [
            // Column3
            { url: 'img/FriendsPlus/Page35/E1/6.jpg'},
          ],
          [
            // Column4
            { url: 'img/FriendsPlus/Page35/E1/7.jpg', isMatching: true, id: 5},
            { url: 'img/FriendsPlus/Page35/E1/8.jpg', isMatching: true, id: 6},
            { url: 'img/FriendsPlus/Page35/E1/9.jpg', isMatching: true, id: 7},
            { url: 'img/FriendsPlus/Page35/E1/10.jpg', isMatching: true, id: 8},
          ],
          
          
        ],
        answers: ['1-7','2-8','3-6','4-5'],
      },
    2: { // Exercise num
      unit : 'Unit 4',
      id :  'WB2-U4-P35-E2',
        audio: '',
        video: '',
        fontSize: 30,
        inputHeight: '100%',
        exerciseKey: 'img/FriendsPlus/Page35/E2/Key/answerKey.png',
        component: D1,
        //titleImage: '',
        //titleQuestion: [{ num: '2', title: 'Count and write the number', color: "#253E8E" }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page35/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page35/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page35/E2/3.jpg', input: true, answer: "I go by car" },
                { url: 'img/FriendsPlus/Page35/E2/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page35/E2/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page35/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page35/E2/7.jpg', input: true, answer: "I go by motorbike" },
                { url: 'img/FriendsPlus/Page35/E2/8.jpg' },
                { url: 'img/FriendsPlus/Page35/E2/9.jpg', input: true, answer: "I go by boat" },
                { url: 'img/FriendsPlus/Page35/E2/10.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
}

export default json;