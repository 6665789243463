import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import UI from '../../components/ExcerciseTypes/UseIt';

const json = {

  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB2-U3-P25-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: 'img/FriendsPlus/Page25/E1/title.jpg',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page25/E1/1.jpg' },
      ],
    ]
  },

  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB2-U3-P25-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page25/E2/key.png',
    inputSize: 100,
    titleImage: 'img/FriendsPlus/Page25/E2/title.jpg',
    // titleQuestion: [{ num: '3', title: 'Write the missing word. ', color: "#203c8e"}],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span>ten <u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'> &emsp; 10 &emsp; </u> </span> &emsp;&emsp; fourteen # <span> thirteen # </span></p>" +
          "<p><span>twelve #</span>&ensp; nine # <span>&emsp;&ensp; fifteen #  </span></p>" +
          "<p><span>sixteen # </span><span> eleven # </span></p>",
        answer: [
          "14", "13",
          "12", "9", "15",
          "16", "11",
        ],
        type: 'longAnwser'
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 3',
    id: 'WB2-U3-P25-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page25/E3/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page25/E3/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page25/E3/05.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page25/E3/07.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/08.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page25/E3/09.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/10.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page25/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/12.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page25/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/14.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page25/E3/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page25/E3/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page25/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/18.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page25/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/20.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page25/E3/21.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/22.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page25/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/24.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page25/E3/25.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/26.jpg', input: 10 },
        { url: 'img/FriendsPlus/Page25/E3/27.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;