
import UI from '../../components/ExcerciseTypes/UseIt';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
   1: { // Exercise num
      unit: 'Unit 2',
      id: 'WB2-U2-P19-E1',
      audio: '',
      video: '',
      component: UI,
      titleImage: '',
      question: [
      ],
      questionImage: [
         [
            { url: 'img/FriendsPlus/Page19/2.jpg' },
         ],
      ]
   },
   2: { // Exercise num
      unit: 'Unit 2',
      id: 'WB2-U2-P19-E2',
      audio: '',
      video: '',
      fontSize: 30,
      inputHeight: '100%',
      // typeInput: 'center',
      exerciseKey: 'img/FriendsPlus/Page19/E2/Key/answerKey.png',
      component: D1,
      //titleImage: '',
      //titleQuestion: [{ num: '2', title: 'Count and write the number', color: "#253E8E" }],
      questionImage: [ // Row
         [
            // Column1
            { url: 'img/FriendsPlus/Page19/E2/1.jpg' },
         ],
         [
            // Column2
            { url: 'img/FriendsPlus/Page19/E2/2.jpg' },
            { url: 'img/FriendsPlus/Page19/E2/3.jpg', input: true, answer: "14" },
            { url: 'img/FriendsPlus/Page19/E2/4.jpg' },
         ],
         [
            // Column3
            { url: 'img/FriendsPlus/Page19/E2/5.jpg' },
         ],
         [
            // Column4
            { url: 'img/FriendsPlus/Page19/E2/6.jpg' },
            { url: 'img/FriendsPlus/Page19/E2/7.jpg', input: true, answer: "13" },
            { url: 'img/FriendsPlus/Page19/E2/8.jpg' },
         ],

      ],
      questions: [
         {
            title: 'The dictionaries are # the shelf',
            answer: ['table']
         },
      ]
   },
   3: { // Exercise num
      unit: 'Unit 2',
      id: 'WB2-U2-P19-E3',
      audio: '',
      video: '',
      component: DesignUnderLine,
      totalInput: 2,
      exerciseKey: 'img/FriendsPlus/Page19/E3/Key/answerKey.png',
      questionImage: [ // Row

         [
            { url: 'img/FriendsPlus/Page19/E3/03.jpg' },
         ],
         [
            { url: 'img/FriendsPlus/Page19/E3/05.jpg' },
            { url: 'img/FriendsPlus/Page19/E3/06.jpg', input: 1 },
            { url: 'img/FriendsPlus/Page19/E3/07.jpg' },
            { url: 'img/FriendsPlus/Page19/E3/08.jpg', input: 2 },
            { url: 'img/FriendsPlus/Page19/E3/09.jpg' },
            { url: 'img/FriendsPlus/Page19/E3/10.jpg', input: 3, isCorrect: true },
            { url: 'img/FriendsPlus/Page19/E3/11.jpg' },
            { url: 'img/FriendsPlus/Page19/E3/12.jpg', input: 4, isCorrect: true },
            { url: 'img/FriendsPlus/Page19/E3/13.jpg' },
            { url: 'img/FriendsPlus/Page19/E3/14.jpg', input: 5 },
            { url: 'img/FriendsPlus/Page19/E3/15.jpg' },
         ],
         [
            { url: 'img/FriendsPlus/Page19/E3/16.jpg' },
         ],
         [
            { url: 'img/FriendsPlus/Page19/E3/17.jpg' },
            { url: 'img/FriendsPlus/Page19/E3/18.jpg', input: 6 },
            { url: 'img/FriendsPlus/Page19/E3/19.jpg' },
            { url: 'img/FriendsPlus/Page19/E3/20.jpg', input: 7 },
            { url: 'img/FriendsPlus/Page19/E3/21.jpg' },
            { url: 'img/FriendsPlus/Page19/E3/22.jpg', input: 8 },
            { url: 'img/FriendsPlus/Page19/E3/23.jpg' },
            { url: 'img/FriendsPlus/Page19/E3/24.jpg', input: 9 },
            { url: 'img/FriendsPlus/Page19/E3/25.jpg' },
            { url: 'img/FriendsPlus/Page19/E3/26.jpg', input: 10 },
            { url: 'img/FriendsPlus/Page19/E3/27.jpg' },
         ],
         [
            { url: 'img/FriendsPlus/Page19/E3/28.jpg' },
         ],

      ],
      questions: [
         {
            title: 'The dictionaries are # the shelf',
            answer: ['table']
         },
      ]
   },

}

export default json;