import L1 from '../../components/ExcerciseTypes/Design/Listen'
import UI from '../../components/ExcerciseTypes/UseIt';

const json = {
  1: { // Exercise num
    unit : 'Unit 3',
    id :  'WB2-U3-P28-E1',
    audio: '',
    video: '',
    component: L1,
    titleImage: '',
    question: [
      {
        imgUrl: [
          "img/FriendsPlus/Page28/E1/1.jpg",
          "img/FriendsPlus/Page28/E1/2.jpg",
          "img/FriendsPlus/Page28/E1/3.jpg",
          "img/FriendsPlus/Page28/E1/4.jpg",
          "img/FriendsPlus/Page28/E1/5.jpg",
          "img/FriendsPlus/Page28/E1/6.jpg",

        ],
        audioUrl: [
          null,
          null,
          "img/FriendsPlus/Page28/E1/Audio/This is my sister..mp3",
          "img/FriendsPlus/Page28/E1/Audio/She's thirsty..mp3",
          "img/FriendsPlus/Page28/E1/Audio/He's sad..mp3",
          "img/FriendsPlus/Page28/E1/Audio/Are these her socks.mp3",
        ],
        isLongAudio: []
      },
    ],
    questionImage: []
  },
  2: { // Exercise num
    unit : 'Unit 3',
    id :  'WB2-U3-P28-E2',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page28/E2/1.jpg' },
      ],
    ]
  },

}

export default json;