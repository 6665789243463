
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: { // Exercise num
    unit: 'Starter',
    id: 'WB2-S-P9-E1',
    audio: '',
    video: '',
    inputHeight: 33,
    fontSize: 30,
    exerciseKey: 'img/FriendsPlus/Page9/E1/Key/answerKey.png',
    component: D1,
    //titleImage: '',
    //titleQuestion: [{ num: '2', title: 'Count and write the number', color: "#253E8E" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page9/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page9/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/3.jpg', input: true, answer: "6" },
        { url: 'img/FriendsPlus/Page9/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/5.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page9/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/7.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page9/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/9.jpg', input: true, answer: "7" },
        { url: 'img/FriendsPlus/Page9/E1/10.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page9/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/12.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page9/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/14.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page9/E1/15.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Starter',
    id: 'WB2-S-P9-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page9/E2/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page9/E2/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/05.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page9/E2/07.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page9/E2/08.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/11.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page9/E2/12.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page9/E2/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/16.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page9/E2/17.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page9/E2/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/19.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;