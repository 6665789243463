import UI from '../../components/ExcerciseTypes/UseIt';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {

  1: { // Exercise num
    unit: 'Unit 2',
    id: 'WB2-U2-P18-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page18/E1/1.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'WB2-U2-P18-E2',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page18/E2/1.jpg' },
      ],
    ]
  },

  3: { // Exercise num
    unit: 'Unit 2',
    id: 'WB2-U2-P18-E3',
    audio: 'img/FriendsPlus/Page18/E3/Audio/Track 004.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page18/E3/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page18/E3/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page18/E3/05.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page18/E3/07.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/08.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page18/E3/09.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/10.jpg', input: 3 },
      ],

      [
        { url: 'img/FriendsPlus/Page18/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/12.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page18/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/14.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page18/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/16.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page18/E3/17.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;