import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";

const json = {
  2: {
    // Exercise num
    unit: "Culture",
    id: "WB2-C-P51-E2",
    audio: "",
    video: "",

    component: UnderLine,
    hideBtnFooter: true,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page51/E2/1.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Culture",
    id: "WB2-C-P51-E3",
    audio: "",
    video: "",

    component: UnderLine,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.png',
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page51/E3/1.jpg" }],
    ],
  },
};
export default json;
