
import LT2 from '../../components/ExcerciseTypes/LineTo/LT2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
    1: { // Exercise num
        unit: 'Starter',
        id: 'WB2-S-P8-E1',
        audio: '',
        video: '',
        inputHeight: 33,
        fontSize: 30,
        typeInput: 'center',
        exerciseKey: 'img/FriendsPlus/Page8/E1/Key/answerKey.png',
        component: D1,
        //titleImage: '',
        //titleQuestion: [{ num: '2', title: 'Count and write the number', color: "#253E8E" }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page8/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page8/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/3.jpg', input: true, answer: "L" },
                { url: 'img/FriendsPlus/Page8/E1/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page8/E1/5.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/6.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page8/E1/7.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/8.jpg', input: true, answer: "k" },
                { url: 'img/FriendsPlus/Page8/E1/9.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/10.jpg', input: true, answer: "M" },
                { url: 'img/FriendsPlus/Page8/E1/11.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page8/E1/12.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/13.jpg', input: true, answer: "H" },
                { url: 'img/FriendsPlus/Page8/E1/14.jpg' },
                { url: 'img/FriendsPlus/Page8/E1/15.jpg', input: true, answer: "j" },
                { url: 'img/FriendsPlus/Page8/E1/16.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page8/E1/17.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    2: { // Exercise num
        unit: 'Starter',
        id: 'WB2-S-P8-E2',
        audio: '',
        video: '',
        component: LT2,
        exerciseKey: 'img/FriendsPlus/Page8/E2/Key/answerKey.png',
        recorder: true,
        toAnchor: '50% -1%',
        fromAnchor: '50% 100%',
        // titleImage: 'img/FriendsPlus/Page4/E1/1.jpg',
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page8/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page8/E2/2.jpg', isMatching: true, id: 1 },
                { url: 'img/FriendsPlus/Page8/E2/3.jpg' },
                { url: 'img/FriendsPlus/Page8/E2/4.jpg', isMatching: true, id: 2 },
                { url: 'img/FriendsPlus/Page8/E2/5.jpg' },
                { url: 'img/FriendsPlus/Page8/E2/6.jpg', isMatching: true, id: 3 },
                { url: 'img/FriendsPlus/Page8/E2/7.jpg' },
                { url: 'img/FriendsPlus/Page8/E2/8.jpg', isMatching: true, id: 4 },
                { url: 'img/FriendsPlus/Page8/E2/9.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page8/E2/10.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page8/E2/11.jpg', isMatching: true, id: 5 },
                { url: 'img/FriendsPlus/Page8/E2/12.jpg', isMatching: true, id: 6 },
                { url: 'img/FriendsPlus/Page8/E2/13.jpg', isMatching: true, id: 7 },
                { url: 'img/FriendsPlus/Page8/E2/14.jpg', isMatching: true, id: 8 },
            ],


        ],
        answers: ['1-6', '2-7', '3-5', '4-8'],
    },

    3: { // Exercise num
        unit: 'Starter',
        id: 'WB2-S-P8-E3',
        audio: '',
        video: '',
        inputHeight: 33,
        fontSize: 30,
        typeInput: 'center',
        exerciseKey: 'img/FriendsPlus/Page8/E3/Key/Key.png',
        component: D1,
        //titleImage: '',
        //titleQuestion: [{ num: '2', title: 'Count and write the number', color: "#253E8E" }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page8/E3/1.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page8/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page8/E3/3.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page8/E3/4.jpg' },
                { url: 'img/FriendsPlus/Page8/E3/5.jpg', input: true, answer: "k" },
                { url: 'img/FriendsPlus/Page8/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page8/E3/7.jpg', input: true, answer: "j" },
                { url: 'img/FriendsPlus/Page8/E3/8.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    4: { // Exercise num
        unit: 'Starter',
        id: 'WB2-S-P8-E4',
        audio: '',
        video: '',
        // typeInput: 'center',
        inputHeight: 33,
        fontSize: 30,
        exerciseKey: 'img/FriendsPlus/Page8/E4/Key/Key.png',
        component: D1,
        //titleImage: '',
        //titleQuestion: [{ num: '2', title: 'Count and write the number', color: "#253E8E" }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page8/E4/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page8/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page8/E4/3.jpg', input: true, answer: "m" },
                { url: 'img/FriendsPlus/Page8/E4/4.jpg' },
                { url: 'img/FriendsPlus/Page8/E4/5.jpg', input: true, answer: "m" },
                { url: 'img/FriendsPlus/Page8/E4/6.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page8/E4/7.jpg' },
                { url: 'img/FriendsPlus/Page8/E4/8.jpg', input: true, answer: "j" },
                { url: 'img/FriendsPlus/Page8/E4/9.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page8/E4/10.jpg' },
                { url: 'img/FriendsPlus/Page8/E4/11.jpg', input: true, answer: "ll" },
                { url: 'img/FriendsPlus/Page8/E4/12.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page8/E4/13.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

}

export default json;